.navbar-link {
  flex-grow: 1; /* This will make each link take up equal width */
  text-align: center; /* Center the text of each link */
  font-size: 150%; /* Increase the font size */
  color: black !important;
  margin-left: 10%;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000; /* Make sure it's above other content */
}
