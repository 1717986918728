.NavTitle{
  font-family: 'Roboto', sans-serif;
  font-size: 160%;
}



* {
  
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
    
   
  }
  a {
    cursor: url(/src/Assets/Cursor3.png),pointer;
  }
  
  .site-wrapper {
    position: relative;  /* establishes a new stacking context */
    z-index: 2;  /* base z-index */
  }
  .custom-icon-btn {
    padding: 0 !important;  /* override padding */
    margin: 0 !important;  /* override margin if any */
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-20px);
    }
    60% {
      transform: translateX(-50%) translateY(-10px);
    }
  }
  
  .bouncing {
    animation: bounce 2s infinite;
  }

  

  
