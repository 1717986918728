.cards {
  padding: 0.5em;
  background: rgba(256, 256, 256, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;  /* Ensure SVG doesn't overflow */
}

.wave {
  fill: white;  /* Adjust this color to match the background */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;  /* Adjust this to control the height of the wave */
  z-index: 1;  /* Make sure the wave is above the content, adjust as needed */
}

.cards__container, .cards__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards__items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  list-style-type: none;
  width: 100%; 
  padding: 0;
}

.card-container {
  margin: 0.5rem;
  width: 30%;
  border-radius: 1rem;
  box-shadow: 0px 10px 8px #999;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.card-img {
  width: 100%;
  height: 100%;
  margin: 0; /* Remove margins */
  height: auto;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
}

.card-img img {
  width: 100%;
  height: 300px;
  object-fit:cover;
}

.card-title {
  width: 90%;
  display: inline-block;

}

.card-title h5 {
  font-size: 2.1em;
  font-weight: bold;
  text-align: center;
  margin-top: 1.5rem;
 
}

.card-description {
  width: 90%;
  display: inline-block;
}

.card-description p {
  font-size: 1.5em;
  text-align: center;


}

.cards h1 {
  font-family: 'Arial Black', Gadget, sans-serif;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}



@media (max-width: 768px) {
  .card-container {
    margin: 0.5rem;
    width: 45%;
   
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  .card-img {
    width: 100%;
    height: 100%;
    margin: 0; /* Remove margins */
    height: auto;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
  }
  
  .card-img img {
    width: 100%;
    height: 100%;
    object-fit:cover;
  }
  
  .card-title {
    width: 90%;
    display: inline-block;
  
  }
  
  .card-title h5 {
    font-size: 150%;
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .card-description {
    width: 90%;
    display: inline-block;
  }
  
  .card-description p {
    font-size: 100%;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 10%;
  }
  
  .cards h1 {
    font-family: 'Arial Black', Gadget, sans-serif;
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
 
  
  
  
 
} 