/* LoginPage.css */
.body {
    display: flex;
    background-color: white;
    height: 100vh;  /* This covers the entire height of the viewport */
    width: 100%; /* This covers the entire width of the viewport */
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .modal-content input {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .modal-content button {
    margin-top: 10px;
  }
  