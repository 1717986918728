

.body {
    height: 100vh;
  
}
.newscard {
    width: 100%;
    height: 100%; /* Adjust height as needed */
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px 0; /* Only vertical margin */
    background: white;
    
}

.newscard-image {
    width: 100%;
    height: 100%; /* To take the full height of the card */
    object-fit: cover;
    position: absolute;
}

.newscard-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 10px;    /* Add top positioning */
    left: 10px; 
    color: white;
    z-index: 5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Optional: Add shadow for better legibility */
}

.delete-btn {
    position: absolute;
    top: 5px;
    right: 5px;
}
.newscard-description {
    font-size: 1rem;
    display: none;
}

.newsgrid {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 20px;
    width: 100%;
    padding: 1rem;
    grid-auto-rows: 30%;
    overflow-y: auto;

}

@media (max-width: 767px) {
    .newscard {
        width: 100%; 
        
    }

    .newscard-description {
        display: none; /* Hide description on mobile */
    }
    /* .newsgrid {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 15%
    } */
    .newsgrid {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 30%
     } 
}