.contact-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* ensures footer stays at the bottom on short content pages */
}

.contact-content {
    flex: 1; /* allows this section to grow and take up all space before the footer */
    background-color: white;
    padding: 5%;
    padding-bottom: 2%;;
    display: flex;
    flex-direction: column; /* This keeps the children stacked vertically */
    justify-content: center; /* This vertically centers the children */
    height: 100%; 
}

.contact-container {
    display: flex; 
    flex-direction: row; /* Horizontal by default for larger screens */
    justify-content: space-between;
    gap: 2%;
   /* To align the contact card and programm card at the top */
    align-items: stretch;

}

.contact-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.contact-info p {
    font-size: 1.2em;
}



.contact-card {
    flex: 1;
    background-color: #fff;
    padding: 2em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.contact-card h2,
.programm-card h2 { /* Assuming programm-card has a h2 title called "Opening Hours" */
    font-size: 2em; /* Adjust this based on your actual font size for the "Opening Hours" title */
    font-weight: bold;
    margin-bottom: 1em;
}

.contact-card p {
    font-size: 1.2em;
    text-align: center;
}

.social-links {
    display: flex;
    gap: 1em;
    justify-content: center;
    margin-top: 1em;
}

.social-links a {
    color: #4285f4;
}


.programm-card {
    flex: 1;
    background-color: #fff;
    padding: 2em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        gap: 1.5em;
    }
    
    .contact-info,
    .programm-card {
        width: 100%;
    }
}
