.newscard {
    width: 100%;
    height: 100%;
    padding-bottom: 250%; /* 2.5* width */
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    background: white;
}

.newscard-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newscard-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
    color: #333;
}

.newscard-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    
}

.newscard-description {
    display: block;
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    height: 33%; /* This will make the description take up the last third of the card */
    overflow: hidden;
    position: relative;
}

.newscard-description::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
.delete-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100; 
    background-color: red;

}
.edit-btn{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 101; 
    background-color: red;
}
@media (max-width: 767px) {
    .newscard {
        width: 100%; 
    }

    .newscard-description {
        display: none; /* Hide description on mobile */
    }
    .newsgrid {
        grid-template-columns: 2fr; /* Single column layout on mobile */
    }
}