
.star {
    display: inline-block;
    color: gold;  /* You can choose any color you prefer */
  }
  
  .star.empty {
    color: #ddd;  /* Choose the color you prefer for the empty star */
  }

.reviewCard {      
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    
  }
  
  .profilePic {
    width: 30px;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .rating {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }
  
  .reviewText {
    margin: 10px 0;
    text-align: center;
  }
  
  .reviewLink {
    margin-top: auto;
    color: #4285f4;
    text-decoration: none;
  }

  .carousel .control-prev, .carousel .control-next {
    background-color: rgba(0, 0, 0, 0.4);  /* semi-transparent black */
    opacity: 1;
    transition: opacity 0.2s;
}

.carousel .control-prev:hover, .carousel .control-next:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.carousel .control-prev.control-disabled, .carousel .control-next.control-disabled {
    opacity: 0.5;
}
.carousel .control-dots .dot {
  background-color: gray;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: none;
  margin: 0 8px;
  transition: background-color 0.2s;
}

.carousel .control-dots .dot.selected {
  background-color: black;
}

  
  @media (max-width: 768px) {
    .reviewCard {
        padding: 30px;
    }

    .rating {
        font-size: 24px; 
    }

    .reviewText {
        font-size: 18px;  
    }
}