.programm-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%; /* Approximately 1/3 of the screen width */
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    margin: 0 auto; /* Centers the card if its parent is display block or flex */
}

.programm-card h2 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 600;
}

.programm-card ul {
    list-style-type: none;
    padding: 0;
}

.programm-card ul li {
    margin-bottom: 12px;
    font-size: 1.4em;
    text-align: center;
    font-weight: 500;
}
