.footer {
    background-color: #f8f9fa;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    bottom: 0;
    width: 100%;
  }
  
  .footer p {
    margin: 0;
    padding: 0;
    color: #6c757d;
  }
  