.body {
 
  background-color: white;
  width: 100%;
  height: 100%; /* adjust as needed */
}

.about-us-card {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  width: 35%;
  height: 90%;
  font-size: 140%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

}


@media (max-width: 768px) {
  .about-us-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 1rem;
  justify-content: center;
  gap: 1rem;

  }
}