@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

body {
  font-family: 'Ubuntu', sans-serif;
}

.card {
  width: 300px;
  height:400px !important;
  perspective: 600px;
  cursor: pointer;
  transition: 0.6s;
  position: relative;
  margin: 25px 0;
  z-index: 2;
}

.card:hover {
  filter: brightness(85%);
}

.card__face {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 10px; 
  transform-style: preserve-3d;
  transition: .4s linear .1s;
}
.simpatizanti-image {
  object-position: left center;  /* This will move the image more to the right */
}
.nostalgic-image {
  object-position: left center;  /* This will move the image more to the right */
}
.card__face--front {
  background-color: #2d2d2d;
  color: white;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  transform: rotateY(0deg);
}

.card.flipped .card__face--front {
  transform: rotateY(180deg);
}

.card__face--back {
  background-color: #fff;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(-180deg);
  flex-direction: column;
}

.card.flipped .card__face--back {
  transform: rotateY(0deg);
}

.card__face--front img {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.card__image-container {
  height: 60%;
  overflow: hidden;
}

.card__title {
  text-align: center;
  font-size: 24px;
}

.card__clickme {
  text-align: center;
  font-size: 180%;
  padding-bottom: 10px;
}

.flip-card-grid {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flip-card-grid > div {
  
  margin: 20px;
}
/* Keep existing styles, then add */

.backtext-section {
    padding: 5px;
  }
  
  .backtext-section h3 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .backtext-section ul {
    list-style: none;
    padding-left: 0;
  }
  
  .backtext-section ul li {
    margin-bottom: 5px;
  }
  @media (max-width: 768px) {
    .card {
      width: 300%; /* Adjusted for mobile viewport */
      perspective: 600px;
      cursor: pointer;
      transition: 0.6s;
      position: relative;
      margin: 25px auto; /* centering the card */
}
.backtext-section {
  padding: 5px;
}

.backtext-section h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.backtext-section ul {
  list-style: none;
  padding-left: 0;
}

.backtext-section ul li {
  margin-bottom: 5px;
}
  }
  